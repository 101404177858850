// @flow strict
import React from 'react';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';

const NotFoundTemplate = () => {
  const { title, subtitle } = useSiteMetadata();

  return (
    <Layout title={`Not Found - ${title}`} description={subtitle}>
      <Sidebar />
      <Page title="Not Found">
        <p>These aren&#39;t the droids you&#39;re looking for.</p>
        <iframe src="https://old.devkishen.com/obi.gif" width="500" height="220" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
      </Page>
    </Layout>
  );
};

export default NotFoundTemplate;
